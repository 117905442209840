<template>
   <!--  Top menu -->
   <nav 
      id="mainNav" 
      class="navbar navbar-expand-md navbar-dark fixed-top"
      :class="$route.path==='/domov' ? '' : 'bg-primary'">      
      <div class="row">
         <div class="col-12 top-bar-mobile">
            <div class="container" align="center">
               <span class="top-bar-text">
                  <i class="fa fa-envelope" style="color: #fff"/> 
                  <a href="mailto:ribogojstvo.colaric@gmail.com" class="headerLink"> ribogojstvo.colaric@gmail.com</a>
               </span>
               <span class="top-bar-text" style="padding-left: 20px">
                  <i class="fa fa-phone"/> 
                  <a href="tel:041 973 991" class="headerLink"> 041 973 991</a>
               </span>
               <span class="top-bar-text" style="padding-left: 20px">
                  <i class="fab fa-facebook"></i>
                  <a class="headerLink" href="https://www.facebook.com/ribogojstvocolaric" target="_blank"> Facebook</a>
               </span>
            </div>
         </div>
         <div class="col-3 col-lg-2">
            <router-link class="navbar-brand" to="/" routerLinkActive="active-link">
               <img src="/static/logo_white.png" class="img-fluid" width="100%">
            </router-link>
         </div>
         <div class="col-9 col-lg-10">
            <div class="row">
               <div class="col-12 top-bar-large">
                  <div class="container">
                     <span class="top-bar-text float-right">
                       <i class="fab fa-facebook"></i>
                        <a class="headerLink" href="https://www.facebook.com/ribogojstvocolaric" target="_blank"> Facebook</a>
                     </span>
                     <span class="top-bar-text float-right mr-5">
                        <i class="fa fa-envelope" style="color: #fff"/> 
                        <a href="ribogojstvo.colaric@gmail.com" class="headerLink"> ribogojstvo.colaric@gmail.com</a>
                     </span>
                     <span class="top-bar-text float-right mr-5" style="padding-left: 20px">
                        <i class="fa fa-phone"/> 
                        <a href="tel:041 973 991" class="headerLink"> 041 973 991</a>
                     </span>
                  </div>
               </div>
            </div>

            <div class="container">
               <button class="navbar-toggler float-right" type="button" data-toggle="collapse" 
                  data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="true" 
                  aria-label="Toggle navigation" @click="showDropDown()">
                  <span class="navbar-toggler-icon"></span>
               </button>

               <div class="collapse navbar-collapse" id="navbarCollapse" style="display: none">
                  <ul id="main" class="navbar-nav ml-auto main-menu list-unstyled">
                     <li 
                        @click="menuToggleLink('menuNo'+i);" 
                        class="parent nav-item"
                        v-for="(menuItem,i) in menus"
                        :id="'menuNo'+i"
                        :key="i" 
                        >
                        <router-link 
                           class="nav-link" 
                           :to="menuItem.state"  
                           v-if="menuItem.type == 'link'" 
                           @click.native="removeCollapseInClass();"
                        >
                           {{ menuItem.name }}
                        </router-link>
                        <a 
                           v-if="menuItem.type == 'sub'" 
                           class="nav-link" 
                           href="javascript:void(0)"                     
                        >
                           {{ menuItem.name }}&nbsp;
                           <i :class="menuItem.icon" @click.stop="menuToggle('menuNo'+i)"></i>
                        </a>
                        <ul 
                           v-if="menuItem.type == 'sub' && menuItem.children" 
                           class="sub-menu arrow-up list-unstyled" >
                           <li 
                              class="nav-item" 
                              v-for="(grandchildItem,i) of menuItem.children"
                              :key="i"
                              >
                              <router-link 
                                 class="nav-link" 
                                 :to="grandchildItem.state"
                                 v-if="grandchildItem.type == 'link'" 
                                 @click.native="removeCollapseInClass()"      
                              >
                                 {{ grandchildItem.name }}
                              </router-link>
                           </li>
                        </ul>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </nav>
</template>
<script>
   export default{
      data(){
         return{
            menus: [
               {
                  state: "/domov",
                  name: "Domov",
                  type:"link"
               },
               {
                  state:"/o-podjetju",
                  name:"O podjetju",
                  type:"link",
               },
               {
                  state:"",
                  name:"Dogodki",
                  type:"sub",
                  icon: 'fa fa-caret-down',
                  children: [
                     { state: 'dogodki', name:'Dogodki', type:"link"},
                     { state: 'projekti', name: 'Projekti', type:"link"},
                  ]
               },
               {
                  state:"/kje-nas-najdete",
                  name:"Kje nas najdete?",
                  type:"link",
               },
               {
                  state:"",
                  name:"Afriski som",
                  type:"sub",
                  icon: 'fa fa-caret-down',
                  children: [
                     { state: 'o-afriskem-somu', name:'O afriškem somu', type:"link"},
                     { state: 'nasi-izdelki', name: 'Naši izdelki', type:"link"},
                  ]
               },
               {
                  state:"/galerija",
                  name:"Galerija",
                  type:"link",
               },

            ]
         }
      },
      mounted(){
         this.onScrollEvent();
      },
      methods:{
         showDropDown() {
            if(document.getElementById('navbarCollapse').style.display == 'none') {
               document.getElementById('navbarCollapse').style.display = 'flex';
            } else {
               document.getElementById('navbarCollapse').style.display = 'none';
            }
         },

         menuToggleLink(id){
            if (document.getElementById(id).classList.contains("open")){
               document.getElementById(id).classList.remove("open");
            } else if(!document.getElementById(id).classList.contains("open")) {
               let elements = document.querySelectorAll(".parent");
               for (var i = 0; i < elements.length; i++) {
                  elements[i].classList.remove('open');
               }
               document.getElementById(id).classList.add("open");
            }   
         },
         addToggleClass(){
            document.querySelector("body").classList.toggle("rtl-enable");            
         },
         removeCollapseInClass(){
            document.getElementById("navbarCollapse").classList.remove('show');
         },
         onScrollEvent(){
            var headerSticky = document.getElementById('mainNav');
            window.onscroll = function() {
               if (window.pageYOffset >= 100) {
                  headerSticky.classList.add("scrollHeader");
               }  else {
                  headerSticky.classList.remove("scrollHeader");
               }
            }
         }
      }
   }
</script>
<style>
   .scrollHeader{
      background-color: #1565c0;
   }
   a.headerLink:link {color: white;}
   a.headerLink:visited {color: white;}

</style>