import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   {
      path: '/',
      redirect: '/domov'
   },
   {
      path: '/domov',
      name: 'domov',
      component: () => import('../views/Pages/domov/Domov.vue')
   },
   {
      path: '/o-podjetju',
      name: 'o-podjetju',
      component: () => import('../views/Pages/oPodjetju/OPodjetju.vue')
   },
   {
      path: '/dogodki',
      name: 'dogodki',
      component: () => import('../views/Pages/dogodki/Dogodki.vue')
   },
   {
      path: '/projekti',
      name: 'projekti',
      component: () => import('../views/Pages/projekti/Projekti.vue')
   },
   {
      path: '/kje-nas-najdete',
      name: 'kje-nas-najdete',
      component: () => import('../views/Pages/lokacija/Lokacija.vue')
   },
   {
      path: '/o-afriskem-somu',
      name: 'o-afriskem-somu',
      component: () => import('../views/Pages/OSomu/OSomu.vue')
   },
   {
      path: '/nasi-izdelki',
      name: 'nasi-izdelki',
      component: () => import('../views/Pages/izdelki/Izdelki.vue')
   },
   {
      path: '/galerija',
      name: 'galerija',
      component: () => import('../views/Pages/galerija/Galerija.vue')
   },
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

export default router
