<template>
   <div>
      <div class="footer-wrapper-2 py-5">
         <div class="container container-full-width text-center">
            <div class="row align-items-center">
               <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div class="bottom-footer">
                     <ul class="list-inline mb-0"  v-if="footer2Menu">
                        <li class="list-inline-item" v-for="(menu,i) of footer2Menu" :key="i">
                           <router-link :to="menu.state" routerLinkActive="active-link" v-if="menu.type === 'link'" class="text-white">
                              <span>{{ menu.name }}</span>
                           </router-link>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div class="social-without-bg">
                     <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                           <a class="text-white" href="https://www.facebook.com/ribogojstvocolaric" target="_blank">
                           <i class="fab fa-facebook-f"></i>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
					<div class="copright text-center col-sm-12 col-md-12 col-lg-12">
					  <span class="text-white ">&copy; {{today.getFullYear()}}, <a style="color: white" href="https://www.onixweb.net">Onixweb d. o. o. </a></span>
				</div>
            </div>
				
         </div><!-- container closed -->
      </div>
   </div>   
</template>
<script>


   export default{
      components:{

      },
      data(){
         return{
				// showSubscriber: true,
            footer2Menu:[
               {
                  state: "domov",
                  name: "Domov",
                  type:"link"
               },
               {
                  state:"o-podjetju",
                  name:"O podjetju",
                  type:"link"
               },
               {
                  state:"dogodki",
                  name:"Dogodki",
                  type:"link"
               },
               {
                  state:"projekti",
                  name:"Projekti",
                  type:"link"
               },
               {
                  state:"kje-nas-najdete",
                  name:"Lokacija",
                  type:"link"
               },
               {
                  state:"o-afriskem-somu",
                  name:"O afriškem somu",
                  type:"link"
               },
               {
                  state:"nasi-izdelki",
                  name:"Izdelki",
                  type:"link"
               },
               {
                  state:"galerija",
                  name:"Galerija",
                  type:"link"
               },

            ],

            today: new Date()
         }
		},
		methods: {
      getUrl(){
			let val = this.$route.path.split("/");
				if(val[1] == 'home')
				{
					this.showSubscriber = false;
				} else {
					this.showSubscriber = true;               
				}
			}
		},
		mounted(){
			this.getUrl();
		},
		watch: {
			$route() {
				this.getUrl();
			}
		},
   }
</script>